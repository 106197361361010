<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	xmlns="http://www.w3.org/2000/svg"
	><g id="flag--navigation-map-maps-flag-gps-location-destination-goal"
		><path
			id="Union"
			fill="currentColor"
			fill-rule="evenodd"
			d="m35.417142857142856 16.549714285714284 0.017142857142857144 0.03428571428571429c0.3942857142857143 0.624 0.9325714285714286 1.5017142857142856 1.5154285714285713 2.52 1.1417142857142857 2.0057142857142853 2.5165714285714285 4.673142857142857 3.219428571428571 7.042285714285713 0.41142857142857137 1.3851428571428572 0.04457142857142857 2.7085714285714286 -0.7714285714285714 3.6651428571428566 -0.7885714285714286 0.9222857142857143 -1.947428571428571 1.4708571428571426 -3.161142857142857 1.5874285714285714l-1.6114285714285712 0.1577142857142857c-3.3737142857142857 0.336 -6.959999999999999 0.6891428571428572 -10.635428571428571 0.6891428571428572s-7.258285714285714 -0.3531428571428571 -10.628571428571428 -0.6857142857142857l-0.8948571428571428 -0.09257142857142857 0 13.748571428571427a2.571428571428571 2.571428571428571 0 0 1 -5.142857142857142 0l0 -18c0 -0.17142857142857143 0.013714285714285714 -0.3325714285714286 0.04457142857142857 -0.49371428571428566a5.211428571428571 5.211428571428571 0 0 1 -0.017142857142857144 -0.456V7.186285714285714c0 -2.6914285714285713 2.0639999999999996 -4.906285714285715 4.707428571428571 -5.163428571428571l1.3028571428571427 -0.13028571428571428c3.370285714285714 -0.3325714285714286 6.953142857142857 -0.6857142857142857 10.628571428571428 -0.6857142857142857 3.6719999999999997 0 7.261714285714285 0.3531428571428571 10.635428571428571 0.6857142857142857l1.6114285714285712 0.16114285714285714c1.2137142857142855 0.11657142857142858 2.3725714285714283 0.6651428571428571 3.161142857142857 1.5908571428571427 0.816 0.9600000000000001 1.1794285714285713 2.2834285714285714 0.7542857142857142 3.6719999999999997 -0.7097142857142856 2.3245714285714287 -2.0879999999999996 4.896 -3.226285714285714 6.822857142857142 -0.5828571428571429 0.977142857142857 -1.1177142857142857 1.8171428571428572 -1.5085714285714285 2.410285714285714Z"
			clip-rule="evenodd"
			stroke-width="3.4285714285714284"
		/></g
	></svg
>
