<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	><path
		d="M40.9958 21.6893L37.0255 17.7204C36.9777 17.6365 36.9517 17.542 36.9497 17.4455L39.0218 11.2247C39.3325 10.4921 39.1676 9.64427 38.6051 9.08144C38.0426 8.51861 37.1949 8.35328 36.4621 8.6635L30.2636 10.7326C30.167 10.7368 30.0707 10.7179 29.9827 10.6776L25.9975 6.69098C25.4763 6.02476 24.5615 5.81136 23.7992 6.17818C23.037 6.54499 22.633 7.39305 22.8284 8.21608L23.5077 14.3001C23.4936 14.3574 23.4716 14.4124 23.4423 14.4636L17.0625 18.0088C16.2946 18.3318 15.8408 19.1316 15.9574 19.9563C16.074 20.7811 16.7317 21.4238 17.5589 21.5213L21.7656 22.4905C21.8977 22.5199 22.0033 22.6192 22.0407 22.7493C22.0782 22.8794 22.0417 23.0195 21.9455 23.1148L6.54433 38.5144C6.07487 38.9839 5.89153 39.6681 6.06336 40.3094C6.2352 40.9507 6.7361 41.4516 7.37739 41.6234C8.01867 41.7953 8.70292 41.6119 9.17237 41.1425L24.572 25.7428C24.6677 25.6476 24.8074 25.6117 24.9371 25.6491C25.0668 25.6864 25.166 25.7912 25.1963 25.9227L26.167 30.1308C26.2645 30.9581 26.9071 31.6158 27.7319 31.7324C28.5567 31.849 29.3565 31.3951 29.6795 30.6273L33.2024 24.2757C33.2467 24.237 33.2969 24.2054 33.351 24.1821L39.4722 24.8599C40.2947 25.0522 41.1404 24.6473 41.5062 23.8859C41.8721 23.1246 41.6599 22.2113 40.9958 21.6893Z"
		fill="currentColor"
	/>
</svg>
